import React from 'react';

const LGExploreArea = () => {
    return (
        <div>
            explore new tags here
        </div>
    );
}

export default LGExploreArea;
