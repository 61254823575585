import React from 'react';

const LGAdminsArea = () => {
    return (
        <div>
            All Admins
        </div>
    );
}

export default LGAdminsArea;
