import React from 'react';

const LGMapArea = () => {
    return (
        <div>
            Map View goes here
        </div>
    );
}

export default LGMapArea;
