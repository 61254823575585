import React from 'react';
import PropTypes from 'prop-types';


const LGProfileArea = () => {
    return (
        <div>
            Here is your profile
        </div>
    );
};


LGProfileArea.propTypes = {

};


export default LGProfileArea;
